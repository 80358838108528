
import axios from 'axios';
import extend from 'extend'
import { useRouter } from "vue-router";

const router = useRouter();
//配置axios
//缓存本页面数据参数

function getType(obj) {
  //获取数据格式
  try {
    var types = {};
    var arr = 'Boolean Number String Function Array Date RegExp Object Error Symbol'.split(
      ' '
    );
    for (var i = 0; i < arr.length; i++) {
      types['[object ' + arr[i] + ']'] = arr[i].toLowerCase();
    }
    return obj == null
      ? String(obj)
      : types[Object.prototype.toString.call(obj)] || 'object';
  } catch (error) {
    return '';
  }
}
//永久地址缓存
// function clearCache() {
//     setTimeout(() => {
//         urlCache = {};
//         clearCache();
//     }, 1000 * 60 * 60 * 12);
// } //12小时缓存失效
let $axios = obj => {
  let ajaxData = ''; //res数据
  let a = {
    url: '',
    type: 'get', //请求方法,默认为get
    data: {}, //需要传递参数
    elseData: {
      //用于其他扩充
      token: false, //默认请求需要token
      typeModule: '', //大类下面的可归类为一类请求的接口说明
      loading: false, //是否需要提交加载
      uploadData: false, //上传图片传递数据开关
      formData: true, //判断数据是否需要formData
      tip: false,
      timeout: 6000000,
      tipTxt: '提交成功！',
      tipTime: 1000,
    },
    config: {
      //设置即将被发送的自定义请求头
      headers: {
        'Cache-Control': 'no-cache'
      }
    },
    _self: {}
  };
  obj = extend(true, a, obj); //合并参数

  //判断是否需要数据转换
  // let n = {
  //   cache(res) {
  //     if (obj.elseData.cache) {
  //       if (res.data.code === 1000) {
  //         urlCache[p] = extend(true, {}, res);
  //         console.log(urlCache[p])
  //       }
  //     }
  //   },
  //   windowCache(res) {
  //     if (obj.elseData.windowCache) {
  //       if (res.data.code === 1000) {
  //         window.windowCache[p] = extend(true, {}, res);
  //         console.log(window.windowCache[p])
  //       }
  //     }
  //   },
  // }
  // if (obj.elseData.type == 'yzd') {
  obj.config.headers['x-requested-with'] = 'XMLHttpRequest';
  //判断是否需要缓存，如果有缓存，直接返回
  // if (urlCache[p] && obj.elseData.cache) {
  //   return new Promise(resolve => {
  //     //copy返回参数，避免操作参数进行不必要的修改
  //     var res = extend(true, {}, urlCache[p]);
  //     console.log('=====', urlCache[p])
  //     resolve(res);
  //   });
  // }
  //页面刷新保留缓存
  // if (window.windowCache[p] && obj.elseData.windowCache) {
  //   return new Promise(resolve => {
  //     //copy返回参数，避免操作参数进行不必要的修改
  //     var res = extend(true, {}, window.windowCache[p]);
  //     resolve(res);
  //   });
  // }


  //发送请求函数
  // let baseURL = 'http://39.107.87.255:6003/api/'
  let newAxios = axios(
    extend(
      true,
      {
        url: obj.url,
        method: obj.type,
        headers: obj.headers,
        baseURL: 'http://www.xinrongji.com/api/',
        // baseURL: 'http://localhost:8000/api/',
        withCredentials: true,
        timeout: obj.elseData.timeout,
        data: obj.type === 'get' ? {} : a.data,
        params: extend(
          true,
          obj.type === 'post' ||
            obj.type === 'put' ||
            obj.type === 'delete'
            ? {}
            : a.data
        )
      },
      obj.config
    )
  )
    .then(res => {
      console.log(res);
      //请求完成
      ajaxData = res.data;
      if (res.data.code === 1000) {
        res.d = res.data.data;
        return Promise.resolve(res);
      } else {
        console.log(res);

      }
    })
    .catch(error => {
      console.log(error);
      let errorMsg = '服务器开小差，请稍后重试！';
      return Promise.reject(ajaxData.msg || ajaxData.message || errorMsg);
    });

  return newAxios;
};


export { $axios };
