<template>
  <div class="bottom">
    <div class="left">
      <img src="../assets/img/logo_bottom.png" alt="" />
    </div>
    <div class="right">
      <div class="right-top">
        {{ $t("地址：北京市朝阳区百⼦湾南⼆路 77 号新荣记办公楼 3 层") }}
      </div>
      <div class="right-center">
        {{ $t("电话") }}：0576-85117797 18612880825
      </div>
      <div class="right-bottom">
        <span>{{
          "Copyright © 2024 新荣记. All Rights Reserved.沪ICP备20020449号-2"
        }}</span>
        <span class="logo">
          <img src="../assets/img/bottom_weibo.svg" alt="" />
          <img src="../assets/img/bottom_wechat.svg" alt="" />
          <img src="../assets/img/bottom_shipinhao.svg" alt=""
        /></span>
      </div>
    </div>
    <el-backtop>
      <div style="cursor: pointer">
        <img
          width="50px"
          height="50px"
          src="../assets//img/backtotop.svg"
          alt=""
        />
      </div>
    </el-backtop>
  </div>
</template>

<style lang="scss" scoped>
.bottom {
  height: 500px;
  background: #e8e8e8;
  padding: 74px 100px 120px 100px;
  display: flex;
  justify-content: space-between;
  font-family: Helvetica, Helvetica;
  font-size: 20px;
  transition: 1s;
  border-top: 5px solid #ded2a9;
  .left {
    img {
      height: 100%;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 110px;
    justify-content: space-around;

    .right-top {
      font-weight: 400;
      color: #535353;
      display: flex;
      justify-content: space-between;
      margin-right: 40px;
    }
    .right-center {
      line-height: 1.8;
      font-weight: 400;
      color: #535353;
    }
    .right-bottom {
      font-family: Helvetica, Helvetica;
      font-weight: 400;
      color: #aaaaaa;
      display: flex;
      justify-content: space-between;
      span:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
      span.logo {
        margin-left: 10px;
        img {
          height: 50px;
          margin-left: 20px;
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .bottom {
    font-size: 20px;
    height: 500px;

    .right {
      margin-left: 100px;
      .right-bottom {
        .right-bottom {
          span.logo {
            img {
              height: 50px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1441px) and (max-width: 1919px) {
  .bottom {
    font-size: 19px;
    height: 450px;

    .right {
      margin-left: 100px;
      .right-bottom {
        span.logo {
          img {
            height: 45px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .bottom {
    font-size: 18px;
    height: 400px;

    .right {
      margin-left: 90px;
      .right-bottom {
        span.logo {
          img {
            height: 40px;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .bottom {
    font-size: 17px;
    height: 400px;
    .right {
      margin-left: 80px;
      .right-bottom {
        span.logo {
          img {
            height: 30px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
