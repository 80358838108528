const baseURL = 'http://www.xinrongji.com/'
// const baseURL = 'http://localhost:8000/'
function isElementInViewport(element) {

  var rect = element.getBoundingClientRect(); // 获取元素相对于视口的位置信息

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom - 200 <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );


}
export { baseURL, isElementInViewport }