import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index";
import "./assets/reset.scss";
import "./assets/variables.scss";
import "./assets/iconfont.css";
import ElementPlus from 'element-plus'

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css

// 导入样式文件

import VueAwesomeSwiper from 'vue-awesome-swiper';


import i18n from './lang'

const app = createApp(App);
app.use(router).use(vue3videoPlay).use(ElementPlus).use(i18n).use(VueAwesomeSwiper).mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}