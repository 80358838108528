<template>
  <el-menu
    class="el-menu-demo"
    :style="{
      background: router.currentRoute.value.name === 'detail' ? '#000' : '',
    }"
    :router="true"
  >
    <img class="logo" src="../assets/img//logo_nav.svg" alt="" />
    <div class="layout">
      <el-menu-item
        v-for="(item, index) in nav"
        :key="index"
        :index="item.path"
        :style="{
          display:
            router.currentRoute.value.name === 'index' && item.title == '预订'
              ? 'none'
              : '',
        }"
        @click="skip(item)"
        >{{ $t(item.title) }}</el-menu-item
      >
      <el-dropdown trigger="click" placement="bottom-end">
        <span class="lang iconfont icon-diqiu"></span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item id="1" @click="handle($event)"
              >简体中文</el-dropdown-item
            >
            <el-dropdown-item id="2" @click="handle($event)"
              >ENGLISH</el-dropdown-item
            >
            <el-dropdown-item id="3" @click="handle($event)"
              >日本語</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </el-menu>
</template>

<script setup lang="js">
import { defineComponent,ref,computed,h,defineEmits} from "vue";
import { ElMenu, ElMenuItem, ElNotification,ElBacktop } from 'element-plus'
import 'element-plus/es/components/notification/style/css'

import nav from "../components/nav.json";
import { useI18n } from 'vue-i18n'
import { useRouter} from 'vue-router'

if(!localStorage.getItem('locale')) localStorage.setItem('locale','zh')

    const { locale } = useI18n()
    if (localStorage.getItem('locale') === 'zh') {

      locale.value = 'zh'
    } else if (localStorage.getItem('locale') === 'en') {

      locale.value = 'en'
    } else if (localStorage.getItem('locale') === 'jp') {

      locale.value = 'jp'
    }
    const router = useRouter()
    const emit = defineEmits(['changeLocale'])
    const handle = (e) => {
      if (e.target.id === '1') {

        localStorage.setItem('locale','zh')
        locale.value = 'zh'
      } else if (e.target.id === '2') {

        locale.value = 'en'
        localStorage.setItem('locale','en')
      } else if (e.target.id === '3') {

        locale.value = 'jp'
        localStorage.setItem('locale','jp')
      }
      emit('changeLocale',locale.value)
    }
    // eslint-disable-next-line no-undef

    const skip = (item)=> {
      if (!item.name) {
        console.log(locale.value)
        if (locale.value === 'zh') {
          ElNotification({
            title: '',
            message: h('i', { style: 'color: #000;font-size:20px' }, '即将推出'),
            duration: 2000,
            zIndex: 9999,
            showClose: false
          })
        } else if (locale.value === 'en') {
          ElNotification({
            title: '',
            message: h('i', { style: 'color: #000;font-size:20px' }, 'coming soon'),
            duration: 2000,
            zIndex: 9999,
            showClose: false
          })

        }  else if (locale.value === 'jp') {
          ElNotification({
            title: '',
            message: h('i', { style: 'color: #000;font-size:20px'}, 'もうすぐ発売します。'),
            duration: 2000,
            zIndex: 9999,
            showClose: false,
          })
        }
        else {
          router.push(item.path)
        }
      }


    // return {
    //   nav,
    //   handle,
    //   router,
    //   skip
    // };
  }
</script>

<style lang="scss">
.el-popper.is-light {
  border: none !important;
}
.el-popper {
  margin-top: 20px;
  .el-popper__arrow {
    display: none !important;
  }
}
.el-dropdown-menu {
  width: 150px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  z-index: 999;
  .el-dropdown-menu__item {
    height: 51px;
    line-height: 51px;
    background: #fff;
    text-align: center;
    transition: 0.3s;
    font-size: 18px;
    cursor: pointer;
  }
  .el-dropdown-menu__item:hover {
    background: #ded2a9;
    color: #fff;
  }
}
.el-menu {
  line-height: 100px;
  height: 100px;
  // background-color: transparent;
  // border-bottom: none;
  line-height: 100px;
  color: #fff;
  font-family: Helvetica, Helvetica;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;
  transition: 1s;

  .layout {
    display: flex;
    height: 100%;
    .lang {
      cursor: pointer;
      color: #fff;
      font-size: 30px;
    }
    .lang:hover {
      color: #fff;
    }
    .el-menu-item {
      height: 100%;
      // border-bottom: 5px solid transparent;
      box-sizing: border-box;
      position: relative;
      transition: 0.5s;
      text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
    .el-menu-item::after {
      content: "";
      position: absolute;
      height: 5px;
      bottom: 0;
      left: 0;
      background-color: transparent;
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.5s;
    }
    .el-menu-item:hover::after {
      content: "";
      position: absolute;
      height: 5px;
      bottom: 0;
      left: 0;
      background-color: #ded2a9;
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
@media (min-width: 1920px) {
  .el-menu {
    font-size: 20px;

    img.logo {
      display: inline-block;
      height: 60px;
    }
    .lang {
      margin-left: 40px;
    }
    .el-menu-item {
      margin: 0 40px;
    }
  }
}

@media (min-width: 1441px) and (max-width: 1919px) {
  .el-menu {
    font-size: 19px;

    img.logo {
      display: inline-block;
      height: 55px;
    }
    .lang {
      margin-left: 30px;
    }
    .el-menu-item {
      margin: 0 30px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .el-menu {
    font-size: 18px;

    img.logo {
      display: inline-block;
      height: 50px;
    }
    .lang {
      margin-left: 20px;
    }
    .el-menu-item {
      margin: 0 20px;
    }
  }
}

@media (max-width: 1199px) {
  .el-menu {
    font-size: 17px;

    img.logo {
      display: inline-block;
      height: 45px;
    }
    .lang {
      margin-left: 15px;
    }
    .el-menu-item {
      margin: 0 15px;
    }
  }
}
</style>
