<template>
  <div class="index">
    <div class="header">
      <Nav></Nav>
    </div>
    <div class="video-contents" :class="flag === true ? 'fadeInOut' : ''">
      <div class="btn-book" @click="router.push('/list')">
        {{ $t("预订") }}
      </div>
      <div class="award-star">
        <img src="../assets/img/award-star.svg" alt="" />
      </div>
      <div class="video-layout" @wheel="handleScroll($event)"></div>
      <span class="iconfont icon-jiantou" @click="down"></span>
      <vue3VideoPlay v-bind="options" :src="options.src" @ended="ended" />
      <div class="dot">
        <span
          v-for="(item, index) in videoList"
          :key="index"
          :class="{ active: i === index }"
          @click="play(index)"
        ></span>
      </div>
    </div>

    <div class="brand-con" v-if="isShow">
      <div class="xinrongji">
        <span class="started">STARTED 1995</span>

        <div class="xinrongji-img">
          <img src="../assets/img/xinrongji.svg" alt="" />
        </div>

        <div class="cover">
          <img src="../assets/img/cover.png" alt="" />
        </div>

        <div class="xinrongji-intro">
          <p>{{ $t("新荣记，其名寓意“ 欣欣向荣 ”。") }}</p>
          <p>
            {{
              $t(
                "公元一九九五年十月一日，新荣记第一家小店在天台宗的祖诞地台州开业。"
              )
            }}
          </p>
          <p>
            {{
              $t(
                "二十余年里，新荣记以东海为根，门店逐渐拓展至北京、上海、香港、深圳、杭州、宁波、成都、西安等城市。"
              )
            }}
          </p>

          <p>
            {{ $t("新荣记坚持 “食必求真、然后至美” 的美食理念，") }}
          </p>
          <p>
            {{ $t("以“真材、真味、真诚”之三味真火，臻达食材本味。") }}
          </p>
          <p>
            {{ $t("2024年2月28日新荣记东京店开业，旨在学习并弘扬中餐文化。") }}
          </p>

          <div class="more" @click="more">
            <span>{{ $t("了解更多") }}</span>
          </div>
        </div>

        <div class="text-bg-en" v-if="locale === 'en'">
          <span>Authentic</span><span>ingredients</span><span>bring</span
          ><span>about</span> <span>ultimacy</span><span>of</span>
          <span>delicacies</span>
        </div>
        <div class="text-bg" v-else>
          {{ $t("食 必 求 真 · 然 后 至 美") }}
        </div>
      </div>

      <div class="rongshu">
        <div id="rongshu_left" class="left">
          <span class="started">I’M UNCLE RONG.</span>
          <div class="title">{{ $t("我是荣叔") }}</div>
          <div class="intro">
            <p>
              {{ $t("是「新荣记」的创始人,也是「荣叔真选」的至味挖掘人。") }}
            </p>
            <p>
              {{
                $t(
                  "我本人喜欢吃，更喜欢研究吃。1995年开了第⼀家新荣记。这些年我去到各地， 学习各种菜系，以自己的理解加以改变与转化。目前新荣记旗下有荣府宴、京 季、芙蓉无双、荣记火锅等子品牌，融合了官府菜、湘菜等。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "我们除内地以外，门店还开到了香港、日本等地，以后可能还会开到世界更多城市。"
                )
              }}
            </p>
          </div>
          <div class="more" @click="more">
            <span>{{ $t("了解更多") }}</span>
          </div>
        </div>
        <div id="rongshu_img" class="right">
          <img src="../assets/img/rongshu.png" alt="" />
        </div>
      </div>

      <Bottom></Bottom>
    </div>
  </div>
</template>

<script setup lang="js">
import { reactive, ref, nextTick, computed, onMounted, h ,onBeforeUnmount} from "vue";
import Nav from '../components/nav.vue'
import Bottom from '../components/bottom.vue'
import { ElBacktop, ElNotification } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { useRouter } from "vue-router";
import { $axios } from '../public/axios'
import {baseURL} from '../public/global'
const router = useRouter();
const { locale } = useI18n()
console.log('locale', locale.value)
const options = reactive({
  title: "", //视频名称
  type: "video/mp4", // 类型
  src: '',
  muted: true, //静音
  webFullScreen: false,
  autoPlay: true, //自动播放
  loop: false, //循环播放
  volume: 0, //默认音量大小
  control: false, //是否显示控制
});
let arr = []
const videoList = ref([])
if(!localStorage.getItem('locale')) localStorage.setItem('locale','zh')
const getBanners = () => {
  $axios({
    url: '/home/banners/',
    data: {
      lang: localStorage.getItem('locale')
    },
    elseData: {
      windowCache: true,
      cache: true
    }
  }).then(res => {
    console.log(res)
    arr = res.data.data.paths
    videoList.value = arr.map(item => baseURL + item);
    console.log(videoList.value); // 输出结果为 [2, 4, 6]
    options.src = videoList.value[0]
  })
}
onBeforeUnmount(() => {
  console.log('11111')
})
onMounted(() => {
  console.log('11111')
  getBanners()
})

let i = ref(0)
let flag = ref(false) //控制视频切换透明度动画
const toggleFadeInOut = () => {
  flag.value = true
  setTimeout(() => {
    flag.value = false
  }, 1000)
}
const ended = (ev) => {
  toggleFadeInOut()
  setTimeout(() => {
    if (i.value === videoList.value.length - 1) {
      i.value = 0
    } else {
      i.value++
    }
    options.src = videoList.value[i.value]
  }, 500)


};
const play = (index) => {
  console.log(index) //点击‘点’切换视频
  toggleFadeInOut()
  flag.value = true
  i.value = index
  setTimeout(() => {
    options.src = videoList.value[index]
  }, 500)

}

const more = () => {
  if (locale.value === 'zh') {
    ElNotification({
      title: '',
      message: h('i', { style: 'color: #000;font-size:20px' }, '即将推出'),
      duration: 2000,
      zIndex: 9999,
      showClose: false
    })
  } else if (locale.value === 'en') {
    ElNotification({
      title: '',
      message: h('i', { style: 'color: #000;font-size:20px' }, 'coming soon'),
      duration: 2000,
      zIndex: 9999,
      showClose: false
    })

  } else if (locale.value === 'jp') {
    ElNotification({
      title: '',
      message: h('i', { style: 'color: #000;font-size:20px' }, 'もうすぐ発売します。'),
      duration: 2000,
      zIndex: 9999,
      showClose: false,
    })
  }
}
let isShow = ref(false) //控制非首屏展示
let elBacktopShow = ref(false)
let handleScroll = (e) => {
  console.log(e.wheelDelta)
  if (e.wheelDelta < 0) {
    e.preventDefault();
    document.querySelector('.icon-jiantou').click()

  } else {
    return
  }
}

let down = () => {     //向下箭头
  isShow.value = true
  nextTick(() => {
    document.querySelector('.brand-con').scrollIntoView({
      behavior: "smooth",
    })
    //'我是荣叔' 部分右侧图片保持与左侧一致
    setTimeout(() => {
          document.querySelector('#rongshu_img').style.height = document.querySelector("#rongshu_left").getBoundingClientRect().height + 'px'
      },10)
  })
}

let docEl = document.documentElement;
// 浏览器可视部分的高度
let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
// 监测滚动事件，'我是荣叔' 部分右侧图片实时与左侧一致
nextTick(() => {
  window.addEventListener('scroll', () => {
    if (router.currentRoute.value.name === 'index') {
      // setTimeout(() => {
      //     document.querySelector('#rongshu_img').style.height = document.querySelector("#rongshu_left").getBoundingClientRect().height + 'px'
      // },10)
    }
    // 页面中内容的总高度
    let docELHeight = docEl.scrollHeight;
    // 页面内已经滚动的距离
    let scrollTop = docEl.scrollTop;

    console.log('scrollTop:' + parseInt(scrollTop), docELHeight - clientHeight)
    // 页面上滚动到底部的条件
    if (scrollTop > 100) {
      // 页面内已经滚动的距离 = 页面中内容的总高度 - 浏览器可视部分的高度
      console.log('到达底部了！');

    }
  })





})
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #000;

  .header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
  }

  .video-contents:deep {
    position: relative;
    display: flex;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;

    .award-star {
      position: absolute;
      bottom: 69px;
      left: 70px;
      z-index: 99;

      img {
        height: 88px;
      }
    }

    .btn-book {
      position: absolute;
      bottom: 50px;
      right: 70px;
      z-index: 9;
      width: 200px;
      height: 60px;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #ffffff;
      font-size: 20px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.5s;
      text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    }

    .btn-book:hover {
      color: rgba(0, 0, 0, 1);
      background-color: #fff;
    }

    .video-layout {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
    }

    .icon-jiantou {
      position: absolute;
      bottom: 20px;
      font-size: 50px;
      font-weight: 800;
      left: 50%;
      margin-left: -40px;
      color: rgb(255, 255, 255, 0.65);
      transform: scaleY(0.7);
      cursor: pointer;
      padding: 20px;
      box-sizing: content-box;
      -webkit-animation: opener 1.5s ease-in-out alternate infinite;
      animation: opener 1.5s ease-in-out alternate infinite;
      z-index: 9;
    }

    .dot {
      position: absolute;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      right: 70px;

      span {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        opacity: 0.5;
        margin: 8px 0;
        cursor: pointer;
        z-index: 9;
      }

      span.active {
        opacity: 1;
      }
    }

    // overflow: hidden;
    .d-player-wrap {
      width: 100vw;
      min-height: 100vh !important;
      z-index: 1;
      background-size: cover;
      object-fit: fill;

      .d-player-video {
        // height: auto !important;
        .d-player-video-main {
          object-fit: cover;
        }
      }

      .d-loading {
        display: none !important;
      }
    }
  }

  .brand-con {
    .xinrongji {
      padding: 0 50px;
      margin-top: 50px;
      position: relative;

      .started {
        font-size: 15px;
        font-weight: 300;
        color: #ae9e79;
        line-height: 40px;
        background-clip: text;
        -webkit-text-fill-color: transparen;
        margin-left: 50px;
      }

      .xinrongji-img {
        margin-left: 50px;
        margin-top: 5px;
        position: relative;
        z-index: 9;

        img {
          height: 119px;
        }
      }

      .cover {
        width: 100%;
        margin-top: -62px;

        img {
          width: 100%;
        }
      }

      .xinrongji-intro {
        text-align: justify;
        width: 680px;
        margin-top: 50px;
        margin-left: 50px;
        font-size: 18px;
        font-family: Source Han Serif;
        font-weight: 300;
        color: #535353;
        line-height: 40px;
        word-wrap: break-word;
        transition: 0.5s;

        .more {
          display: inline-block;
          font-size: 20px;
          font-family: Source Han Serif;
          font-weight: 300;
          color: #aaaaaa;
          line-height: 1.2;
          display: flex;
          justify-content: flex-end;
          margin-top: 120px;
          margin-right: 0px;
          transition: 0.5s;

          span {
            display: inline-block;
            border-bottom: 1px solid #aaaaaa;
            cursor: pointer;
          }
        }
      }

      .text-bg {
        width: 85px;
        height: 741px;
        font-size: 70px;
        font-family: Source Han Serif;
        font-weight: 700;
        color: rgba(174, 158, 121, 0.3);
        line-height: 80px;
        position: absolute;
        bottom: -515px;
        right: 50px;
        z-index: 9;
        transition: 0.5s;
        text-align: center;
      }

      .text-bg-en {
        width: 85px;
        height: 741px;
        font-size: 70px;
        font-family: Source Han Serif;
        font-weight: 700;
        color: rgba(174, 158, 121, 0.3);
        line-height: 80px;
        position: absolute;
        bottom: -410px;
        right: 50px;
        transform: rotate(90deg);
        transform-origin: top;
        z-index: 9;
        transition: 0.5s;

        span {
          margin-left: 25px;
        }
      }

      @media (min-width: 1920px) {
        .started {
          font-size: 15px;
        }

        .xinrongji-intro {
          font-size: 18px;
        }

        .more {
          font-size: 20px;
        }

        .text-bg,
        .text-bg-en {
          font-size: 70px;
        }
      }

      @media (min-width: 1441px) and (max-width: 1919px) {
        .started {
          font-size: 14px;
        }

        .xinrongji-intro {
          font-size: 17px;
        }

        .more {
          font-size: 19px;
        }

        .text-bg,
        .text-bg-en {
          font-size: 65px;
        }
      }

      @media (min-width: 1200px) and (max-width: 1440px) {
        .started {
          font-size: 13px;
        }

        .xinrongji-intro {
          font-size: 16px;
        }

        .more {
          font-size: 18px;
        }

        .text-bg,
        .text-bg-en {
          font-size: 60px;
        }
      }

      @media (max-width: 1199px) {
        .started {
          font-size: 12px;
        }

        .xinrongji-intro {
          font-size: 15px;
        }

        .more {
          font-size: 17px;
        }

        .text-bg,
        .text-bg-en {
          font-size: 55px;
        }
      }
    }

    .rongshu {
      padding: 0 50px;
      margin-top: 135px;
      margin-bottom: 233px;
      position: relative;
      display: flex;
      justify-content: space-between;

      .left {
        height: fit-content;
        margin-right: 100px;
        width: calc(50% - 100px);
        max-width: 730px;

        .started {
          font-size: 15px;
          font-weight: 300;
          color: #ae9e79;
          line-height: 40px;
          background-clip: text;
          -webkit-text-fill-color: transparen;
          margin-left: 50px;
          transition: 0.5s;
        }

        .title {
          font-size: 100px;
          font-family: Source Han Serif;
          font-weight: 500;
          color: #000000;
          margin-left: 50px;
          transition: 0.5s;
          white-space: pre-line;
          letter-spacing: 10px;
        }

        .intro {
          text-align: justify;
          margin-top: 110px;
          margin-left: 50px;
          font-size: 18px;
          font-family: Source Han Serif;
          font-weight: 300;
          color: #535353;
          line-height: 40px;
          word-wrap: break-word;
          transition: 0.5s;
        }

        .more {
          display: inline-block;
          font-size: 20px;
          font-family: Source Han Serif;
          font-weight: 300;
          color: #aaaaaa;
          line-height: 1.2;
          display: flex;
          justify-content: flex-end;
          margin-top: 140px;
          margin-left: 50px;
          transition: 0.5s;

          span {
            display: inline-block;
            border-bottom: 1px solid #aaaaaa;
            cursor: pointer;
          }
        }

        @media (min-width: 1920px) {
          .started {
            font-size: 15px;
          }

          .title {
            font-size: 100px;
          }

          .intro {
            font-size: 18px;
            margin-top: 110px;
          }

          .more {
            font-size: 20px;
            margin-top: 140px;
          }
        }

        @media (min-width: 1441px) and (max-width: 1919px) {
          .started {
            font-size: 14px;
          }

          .title {
            font-size: 93px;
          }

          .intro {
            font-size: 17px;
            margin-top: 80px;
          }

          .more {
            font-size: 19px;
            margin-top: 110px;
          }
        }

        @media (min-width: 1200px) and (max-width: 1440px) {
          .started {
            font-size: 13px;
          }

          .title {
            font-size: 86px;
          }

          .intro {
            font-size: 16px;
            margin-top: 50px;
          }

          .more {
            font-size: 18px;
            margin-top: 80px;
          }
        }

        @media (max-width: 1199px) {
          .started {
            font-size: 12px;
          }

          .title {
            font-size: 79px;
          }

          .intro {
            font-size: 15px;
            margin-top: 30px;
          }

          .more {
            font-size: 17px;
            margin-top: 50px;
          }
        }
      }

      .right {
        margin-right: 125px;
        overflow: hidden;
        display: flex;
        justify-content: center;

        img {
          height: 100%;
          transition: 0.5s;
        }
      }
    }
  }

  .fadeInOut {
    animation: fadeInOut 1s ease-in-out;
  }
}

@keyframes opener {
  0% {
    bottom: 20px;
  }

  50% {
    bottom: 40px;
  }

  100% {
    bottom: 20px;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
