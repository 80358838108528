<template>
  <router-view v-if="isRouterAlive"></router-view>
</template>

<script lang="js" setup>

import { reactive, ref, nextTick, computed, onMounted, h,provide } from "vue";
import { useI18n } from 'vue-i18n'



const { locale } = useI18n()
const isRouterAlive = ref(true)
const reload = () => {
  isRouterAlive.value = false
  nextTick(() => {
    isRouterAlive.value = true
  })
}

if (localStorage.getItem('locale') === 'zh') {
  document.title = '新荣记'
} else if (localStorage.getItem('locale') === 'en') {
  document.title = 'Xin Rong Ji'
} else if (localStorage.getItem('locale') === 'jp') {
  document.title = '新栄記'
}

provide('reload', reload)
</script>

<style lang="scss" scoped></style>
