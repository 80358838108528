import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Index from "../views/index.vue";
let routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    children: [],
  },
  {
    path: "/list",
    name: "list",
    component: () => import('../views/list.vue'),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import('../views/detail.vue'),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
